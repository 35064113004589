.taskode {
  &-header-button-collab {
    display: none;

    @media (min-width: 720px) {
      display: block;
    }
  }

  &-content {
    &-block {
      &-1 {
        @media (min-width: 960px) {
          display: flex;
          align-items: flex-start;
          gap: 64px;
        }

        p {
          font-size: 16px;
        }
      }

      &-2 {
        padding: 32px;
        border-radius: 20px;
        background-color: var(--backgroundSecondary);

        @media (min-width: 960px) {
          display: flex;
          align-items: flex-start;
          gap: 32px;
        }

        .taskode-content-block-image {
          @media (min-width: 960px) {
            margin: 48px 48px 48px 0;
          }
        }
      }

      &-3 {
        &-1 {
          margin: 0 auto;
          padding: 32px 0 16px;
          max-width: 540px;
          text-align: center;
        }

        &-2 {
          @media (min-width: 960px) {
            display: flex;
            align-items: flex-start;
            gap: 32px;
          }
        }

        .taskode-content-block-image {
          margin: 0;
        }
      }

      &-4 {
        position: relative;
        padding: 48px 0;

        @media (min-width: 960px) {
          display: flex;
          align-items: flex-start;
          gap: 32px;
        }

        .taskode-content-block-image {
          @media (min-width: 960px) {
            margin: 48px 0 0;
          }
        }

        &::after {
          content: '';
          position: absolute;
          left: 50%;
          top: 0;
          z-index: -1;
          width: 100vw;
          height: 100%;
          margin-left: -50vw;
          background-color: var(--backgroundSecondary);
        }
      }
    }
  }
}
